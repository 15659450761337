/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ca86dc3e-8416-4fda-bc90-18e965246caf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WOV5o5t75",
    "aws_user_pools_web_client_id": "6l6si3crpikk8c08dtt5t7pche",
    "oauth": {}
};


export default awsmobile;
